import './App.scss';
import React from 'react';
import { connect } from 'react-redux';

import { ThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';

import { theme } from './ThemeOverrides';

import JobPost from './JobPost';

function App(props) {
  const renderJobsListing = (jobsListing) => {
    if (!jobsListing.hasOwnProperty('jobs') || !jobsListing['jobs'].length) {
      return (
        <TableBody><TableRow><TableCell>
          There are no open job requisitions at this time.
        </TableCell></TableRow></TableBody>
      )
    }

    var jobPosts = jobsListing['jobs'].map((jobData) =>
      <JobPost
        key={jobData['jobId']}
        id={jobData['jobId']}
        title={jobData['title']}
        locationMetro={jobData['jobLocation']['metro']}
        locationCity={jobData['jobLocation']['city']}
        locationState={jobData['jobLocation']['state']}
        displayUrl={jobData['displayUrl']}
      />
    );

    return <TableBody>{jobPosts}</TableBody>
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <TableContainer>
          <Table aria-label="jobs table">
            {renderJobsListing(props.jobsListing)}
          </Table>
        </TableContainer>
      </div>
    </ThemeProvider>
  );
}

function mapStateToProps(state) {
  return { ...state };
}

export default connect(mapStateToProps)(App)
