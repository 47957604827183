const jobsReducer = (state = {jobsListing: {}}, action) => {
  switch(action.type) {
    case 'FETCH_JOBS_SUCCESS':
        return Object.assign({}, state, action.payload);
    default:
        return state;
  }
}

export default jobsReducer;
