const fetchJobsSuccess = jobsListing => ({
  type: 'FETCH_JOBS_SUCCESS',
  payload: { jobsListing }
})

export const initStore = () => {
  return async dispatch => {
    const guid = '7f238f75-c607-411c-b47b-74e41a20ba81';
    const url = 'https://recruiting.paylocity.com/recruiting/v2/api/feed/jobs/' + guid;

    try {
      const jobsListing = await fetch(url).then(response => response.json());
      dispatch(fetchJobsSuccess(jobsListing));
    } catch(err) {
      return console.log(err);
    }
  }
}
