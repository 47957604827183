import './JobPost.scss';
import React from 'react';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import OpenInNew from '@material-ui/icons/OpenInNew';

  
function JobPost(props) {
  const {
    id,
    title,
    locationMetro,
    locationCity,
    locationState,
    displayUrl,
  } = props;

  const get_location = (metro, city, state) => {
    const cityState = (city ? city + ", " + state : state);

    if (metro) {
      return <span className="job-location">{metro}<br/>{cityState}</span>
    }

    return <span className="job-location">{cityState}</span>
  }

  /* Removed publish date from listing
  const datetime = new Date(publishedDate + "Z");
  const formattedDate = datetime.toLocaleString('en-US');
          <span className="job-publish-date">{formattedDate}</span>
  */

  return (
    <TableRow>
        <TableCell>
          <span className="job-title">{title}</span> <br/>
          <span className="job-id">Req #{id}</span> <br/>
        </TableCell>
        <TableCell>
          {get_location(locationMetro, locationCity, locationState)}
        </TableCell>
        <TableCell align="center">
          <div className="job-details">
            <a href={displayUrl} target="_blank" rel="noreferrer">
              <OpenInNew style={{ fontSize: "1.2rem" }} />
            </a>
          </div>
        </TableCell>
    </TableRow>
  );
}

export default JobPost;
