import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  overrides: {
    MuiTable: {
      root: {
        backgroundColor: '#222'
      }
    },
    MuiTableRow: {
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: '#222'
        }
      }
    },
    MuiTableCell: {
      head: {
        color: '#fff',
      },
      body: {
        color: '#fff',
      }
    }
  }
});
